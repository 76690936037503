<script setup lang="ts">

import { useField } from "vee-validate";
import {cn} from "~/lib/utils";

interface Item {
    id: number;
    name: string;
}

interface Props {
    items?: Array<any>;
    max?: number;
    name?: string;
    rules?: string;
    immutable?: boolean;
    itemClass: string;
}

const props = withDefaults(defineProps<Props>(), {
    items: [],
    max: 0,
    name: 'tags',
    rules: '',
    immutable: false,
    itemClass: '',
});

const model = defineModel<any>({
    default: []
});

function isSelected(id: number): boolean {
    return model.value?.some((item: any) => item === id);
}

function toggleItem(item: Item): void {
    if (props.immutable) return;
    const index = model.value?.findIndex((itm: any) => itm === item.id);
    if (index === -1 || index === undefined || index === null) {
        if (!Array.isArray(model.value)) {
            model.value = [];
        }
        if (props.max > 0 && model.value?.length >= props.max) {
            return;
        }
        setTimeout(() => {
            model.value.push(item.id);
        }, 1);
    } else {
        model.value.splice(index, 1);
    }
}
</script>

<template>
    <div class="flex flex-wrap gap-2 w-fit">
        <UiBadge
            @click="toggleItem(item)"
            v-for="item in items"
            :class="cn('spec-button text-xs rounded-lg bg-transparent text-primary-800 border-primary-900 font-medium w-fit text-nowrap hover:bg-primary-50 py-1 px-2', itemClass, { 'selected': isSelected(item.id) || immutable, 'cursor-pointer': !immutable })"
            >
            {{ item.name }}
        </UiBadge>
    </div>
</template>

<style scoped lang="scss">
.spec-button {
    border: 1px solid;

    &.selected {
        color: #fff;
        background-color: #2D9F75;
    }
}
</style>